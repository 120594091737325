import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Link } from "@mui/material";
import { useContext } from "react";
import { Logo } from "../../../__generated__/svgComponents/Logo";
import { ColorModeContext } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { DrawerMobileMenuProps } from "./DrawerMobileMenuInterfaces";
import { useDrawerMobileMenuStyles } from "./DrawerMobileMenuStyles";

export const DrawerMobileMenu = (props: DrawerMobileMenuProps): JSX.Element => {
  const { classes, cx } = useDrawerMobileMenuStyles();

  // definition unvollständiger state-hook -> tot

  const { toggleColorMode } = useContext(ColorModeContext);

  return (
    <Box className={cx(classes.wrapper, props.className)} height="100%">
      <Drawer
        anchor="top"
        open={props.isOpen}
        onClose={props.onClose}
        className={classes.drawerWrapper}
      >
        <Link to="/">
          <Logo className={cx(classes.logoInDrawer, classes.logoSize)} />
        </Link>

        <IconButton onClick={props.onClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>

        <Box className={classes.drawerStyles}>{props.children}</Box>
      </Drawer>
    </Box>
  );
};
