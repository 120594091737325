/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CacheProvider } from "@emotion/react";
import "@fontsource/cormorant-garamond/300-italic.css";
import "@fontsource/lato";
import "@fontsource/montserrat-alternates";
import "@fontsource/montserrat-alternates/400.css";
import "@fontsource/montserrat-alternates/500.css";
import "@fontsource/montserrat-alternates/700.css";
import "@fontsource/montserrat-alternates/900.css";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import { merge, noop } from "lodash-es";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
// import { makeStyles } from "tss-react/mui";
import { defaultLanguage } from "../../../../config/LanguageConfiguration";
import { ComponentWithChildren } from "../../../interfaces/reactComponentInterfaces";
import { ColorMode, createThemeWithColorMode } from "../../../layouts/theme";
import { useSiteInfoQuery } from "../../../queries/siteInfoQuery";
import HeaderNavigation from "../../02-molecules/HeaderNavigation/HeaderNavigation";
import Footer from "../../04-templates/Footer/Footer";
import { TruendoConsentProvider } from "../TruendoCookieConsent/TruendoConsentProvider";
import { makeMuiCache } from "./../../../helpers/general/makeMuiCache";
import { useDayJs } from "./useDayJs";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const muiCache = makeMuiCache();

interface ColorModeData {
  colorMode: ColorMode;
  isContrastMode: boolean;
  toggleColorMode: VoidFunction;
}

const queryClient = new QueryClient();

export const ColorModeContext = createContext<ColorModeData>({
  colorMode: ColorMode.Regular,
  toggleColorMode: noop,
  isContrastMode: false,
});

export const useColorMode = (): ColorModeData => useContext(ColorModeContext);

/**
 * Wraps all page content globally:
 *  - does not unmount on page navigation
 *  - used for app-wide concerns, such as (theming, state, static meta, layouts …)
 *
 * Inserted through `gatsby-plugin-layout`, which injects `children`.
 */
const GlobalWrapper: ComponentWithChildren = ({ children }) => {
  const siteInfo = useSiteInfoQuery();

  useDayJs();

  const [colorMode, setColorMode] = useState<ColorMode>(ColorMode.Regular);

  const toggleColorMode = useCallback(() => {
    // The regular, "non-functional" approach to setting the color mode.
    // We have a dependency on the current state (colorMode).

    // const newColorMode =
    //   colorMode === ColorMode.Contrast ? ColorMode.Regular : ColorMode.Contrast;
    // setColorMode(newColorMode);

    // The functional approach for the setter:
    // The callback gets the previous value, our hook has no dependencies.
    setColorMode(
      (previousState: ColorMode): ColorMode =>
        previousState === ColorMode.Contrast
          ? ColorMode.Regular
          : ColorMode.Contrast
    );
  }, []);

  const context: ColorModeData = {
    colorMode,
    isContrastMode: colorMode === ColorMode.Contrast,
    toggleColorMode,
  };

  const theme = useMemo(() => createThemeWithColorMode(colorMode), [colorMode]);

  // This is somehow necessary because of our strict mode and the maybe type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
  const seo = siteInfo.wp as any;
  return (
    <>
      {/* setting language as attribute here also because some pages do not have seo and it does not work if we don't add it here */}

      <Helmet htmlAttributes={{ lang: defaultLanguage }}>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <CacheProvider value={muiCache}>
          <ColorModeContext.Provider value={context}>
            <TruendoConsentProvider>
              <ThemeProvider theme={theme}>
                <HeaderNavigation />

                <SEOContext.Provider
                  value={{
                    global: merge(seo, {
                      schema: { inLanguage: defaultLanguage },
                    }),
                  }}
                >
                  <CssBaseline />

                  <Box
                    component="main"
                    style={{
                      marginTop: "calc(2rem + 60px)",
                      marginBottom: "2rem",
                    }}
                  >
                    {children}
                  </Box>
                </SEOContext.Provider>

                <Footer />
              </ThemeProvider>
            </TruendoConsentProvider>
          </ColorModeContext.Provider>
        </CacheProvider>
      </QueryClientProvider>
    </>
  );
};

export default GlobalWrapper;
