import ContrastIcon from "@mui/icons-material/Contrast";
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import { useBooleanState } from "@nvon/react-toolbox";
import { Link } from "gatsby";
import { useContext, useEffect } from "react";
import { Logo } from "../../../__generated__/svgComponents/Logo";
import { ColorModeContext } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import DesktopMenu from "../DesktopMenu/DesktopMenu";
import MobileMenu from "../MobileMenu/MobileMenu";
import { useHeaderNavigationStyles } from "./HeaderNavigationStyles";
import { useHeaderNavigationViewModel } from "./HeaderNavigationViewModel";

const HeaderNavigation = (): JSX.Element => {
  const { state: isMounted, on: setIsMountedTrue } = useBooleanState(false);

  useEffect(setIsMountedTrue, [setIsMountedTrue]);

  const { classes, cx } = useHeaderNavigationStyles({
    isMounted,
  });
  const { allMenuItems } = useHeaderNavigationViewModel();

  const { toggleColorMode } = useContext(ColorModeContext);

  return (
    <Box
      className={classes.wrapper}
      style={{
        position: "fixed",
        flexGrow: 1,
        top: 0,
        width: "100%",
        zIndex: 100,
      }}
    >
      <AppBar position="static">
        <Container>
          <Toolbar className={cx(classes.toolbar, "pj-toolbar")}>
            <Typography
              className={classes.logoWrapper}
              variant="h6"
              noWrap={true}
              component="div"
            >
              <Link className="classes.logoLink" to="/">
                <Logo className={classes.logoSize} />
              </Link>
            </Typography>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

            <DesktopMenu
              className={classes.desktopMenu}
              menuItems={allMenuItems}
              style={{
                opacity: 0,
              }}
            />

            <ContrastIcon
              className={cx(classes.pointer, classes.position)}
              onClick={toggleColorMode}
              style={{
                opacity: 0,
              }}
            />

            <MobileMenu
              style={{
                opacity: 0,
              }}
              className={classes.mobileMenu}
              menuItems={allMenuItems}
            />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default HeaderNavigation;
