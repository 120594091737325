import { Box, Link, Typography } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { useColorMode } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { DesktopMenuProps } from "./DesktopMenuInterfaces";
import { useDesktopMenuStyles } from "./DesktopMenuStyles";

const DesktopMenu = (props: DesktopMenuProps): JSX.Element => {
  const { isContrastMode } = useColorMode();
  const { classes, cx } = useDesktopMenuStyles({ isContrastMode });

  return (
    <Box
      // Using style here since the regular styles were somehow not showing on SSR
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "100%",
        alignItems: "center",
        ...props.style,
      }}
      className={cx(classes.wrapper, props.className)}
    >
      {props.menuItems.map((item) => {
        return (
          <Box key={item.href} className={classes.menuItemWrapper}>
            <Link to={item.href} component={GatsbyLink}>
              <Typography variant="h6">{item.label}</Typography>
            </Link>

            {!!item.childItems?.length && (
              <Box
                style={{ opacity: 0 }}
                className={cx(classes.subMenu, "subMenu")}
              >
                {item.childItems.map((childMenuItem) => (
                  <Link
                    key={childMenuItem.label}
                    to={childMenuItem.href}
                    component={GatsbyLink}
                  >
                    <Typography variant="h6">{childMenuItem.label}</Typography>
                  </Link>
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default DesktopMenu;
