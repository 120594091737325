import { Box } from "@mui/material";
import { LogoWeiss } from "../../../__generated__/svgComponents/LogoWeiss";
import { LogoWhiteAtomProps } from "./LogoWhiteAtomInterfaces";
import { useLogoWhiteAtomStyles } from "./LogoWhiteAtomStyles";

export const LogoWhiteAtom = (props: LogoWhiteAtomProps): JSX.Element => {
  const { classes, cx } = useLogoWhiteAtomStyles();

  return (
    <Box className={cx(classes.wrapper, props.className)}>
      <LogoWeiss />
    </Box>
  );
};
