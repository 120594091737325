import { makeStyles } from "tss-react/mui";

export const useDrawerMobileMenuStyles = makeStyles({
  name: "DrawerMobileMenu",
})((theme) => ({
  wrapper: {},
  drawerStyles: {
    height: "100% !important",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
      padding: "15%",
      fontWeight: "bold",
      fontSize: "20px",
    },
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(1),
  },
  drawerWrapper: {
    "& .MuiPaper-root": {
      height: "100% !important",
    },
  },
  logoInDrawer: {
    position: "absolute",
    left: theme.spacing(3),
    top: theme.spacing(1.5),
  },

  // fixe größe, min width = max width
  logoSize: {
    marginRight: "70px",
    width: "140px",
    "@media (max-width: 876px)": {
      maxWidth: "140px",
      marginRight: "0px",
    },
  },
}));
