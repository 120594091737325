import { TruendoConsentData } from "./TruendoCookieConsentInterfaces";

/**
 * The consent initially set before the user makes any adjustments.
 */
export const initialConsentData: TruendoConsentData = {
  necessary: true,
  preferences: false,
  statistics: false,
  services: {
    twitter_sharing: false,
    google_youtube: false,
    facebook_instagram: false,
  },
};
