import { Helmet } from "react-helmet";
import { TruendoCookieConsentProps } from "./TruendoCookieConsentInterfaces";

const TruendoCookieConsent = (
  props: TruendoCookieConsentProps
): JSX.Element => {
  return (
    <Helmet>
      <script
        id="truendoAutoBlock"
        type="text/javascript"
        src="https://cdn.priv.center/pc/truendo_cmp.pid.js"
        data-siteid={props.truendoSiteId}
      />
    </Helmet>
  );
};

export default TruendoCookieConsent;
