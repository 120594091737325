/**
 * The max width of containerized content.
 * This is applied to the <Container> element on xl viewports.
 *
 * All other viewports currently use the default maxWidth.
 */
export const containerMaxWidth = 1600;
export const containerPaddingBigDevices = 8;
export const containerPaddingSmallDevicesTopBottom = 5;
export const containerPaddingSmallDevicesLeftRight = 1;
export const headerHeight = 80; // px

// truendo cookie consten
export const truendoSiteId = "5c2c5a73-e639-4efa-8212-50d63f8747a3";

// See https://day.js.org/docs/en/display/format#list-of-localized-formats
export const dateFormatLong = "L";

export const dateFormatMonth = "MMMM YYYY";
