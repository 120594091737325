import { makeStyles } from "tss-react/mui";

export const useDesktopMenuStyles = makeStyles<{ isContrastMode: boolean }>()(
  (theme, { isContrastMode }) => ({
    wrapper: {},
    menuItemWrapper: {
      padding: theme.spacing(0, 1),
      position: "relative",

      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        "& h6": {
          color: isContrastMode ? theme.palette.common.white : undefined,
        },

        borderRadius: theme.spacing(1),
        ".subMenu": {
          opacity: 1,
          pointerEvents: "all",

          "& h6": {
            paddingTop: "0.3rem !important",
            paddingBottom: "0.3rem !important",
            paddingLeft: "1.2rem !important",
            paddingRight: "1.2rem !important",
            fontSize: "0.8rem",
            color: isContrastMode ? theme.palette.common.black : undefined,

            "&:hover": {
              color: isContrastMode ? theme.palette.common.white : undefined,
              backgroundColor: theme.palette.secondary.light,
              borderRadius: theme.spacing(1),
            },
          },
        },
      },
    },
    subMenu: {
      padding: theme.spacing(1, 0),
      left: 0,
      pointerEvents: "none",
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
      position: "absolute",
      minWidth: "100%",
    },
  })
);
