import { makeStyles } from "tss-react/mui";

export const useLogoWhiteAtomStyles = makeStyles({
  name: "LogoWhiteAtom",
})((theme) => ({
  wrapper: {
    width: "208px",
    "svg *": {
      fill: "white !important",
    },
  },
}));
