import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import { pathForCategory } from "../../../helpers/general/links/pathForCategory";
import { isMainCategory } from "../../../helpers/wordpress/isMainCategory";
import { isSubCategory } from "../../../helpers/wordpress/isSubCategory";
import { Category } from "../../../interfaces/Category";
import {
  HeaderNavigationViewModel,
  MenuItem,
} from "./HeaderNavigationInterfaces";

const menuItemFromCategory = (category: Category): MenuItem => ({
  href: pathForCategory(category),
  label: category.name?.toUpperCase() || "",
  childItems: [],
});

const fixedMenuItems: MenuItem[] = [
  {
    href: "/ueber_uns",
    label: "ÜBER UNS",
    childItems: [],
  },
  {
    href: "/autorinnen",
    label: "AUTOR*INNEN",
    childItems: [],
  },
];

export const useHeaderNavigationViewModel = (): HeaderNavigationViewModel => {
  const data = useStaticQuery<GatsbyTypes.AllCategoriesQuery>(graphql`
    query AllCategories {
      allCategories: allWpCategory {
        nodes {
          id
          name
          parentId
          slug
        }
      }
    }
  `);

  const allMenuItems = useMemo(() => {
    const allCategories = (data.allCategories?.nodes || []).filter(
      (cat) => cat.slug !== "uncategorized"
    ) as Category[];

    const mainCategoryEntries: [string, MenuItem][] = allCategories
      .filter(isMainCategory)
      .map((category) => [category.id, category])
      .map(([key, value]) => [
        key,
        menuItemFromCategory(value as Category),
      ]) as [string, MenuItem][];

    const mainCategories = Object.fromEntries(mainCategoryEntries);

    const childCategories = allCategories.filter(isSubCategory);

    childCategories.forEach((category) => {
      const parentCategory = mainCategories[category.parentId as string];
      parentCategory.childItems.push(menuItemFromCategory(category));
    });

    return [...Object.values(mainCategories), ...fixedMenuItems];
  }, [data.allCategories?.nodes]);

  return {
    allMenuItems,
  };
};
