exports.components = {
  "component---src-components-04-templates-article-full-article-full-tsx": () => import("./../../../src/components/04-templates/ArticleFull/ArticleFull.tsx" /* webpackChunkName: "component---src-components-04-templates-article-full-article-full-tsx" */),
  "component---src-components-04-templates-author-site-template-author-site-template-tsx": () => import("./../../../src/components/04-templates/AuthorSiteTemplate/AuthorSiteTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-author-site-template-author-site-template-tsx" */),
  "component---src-components-04-templates-category-template-category-template-tsx": () => import("./../../../src/components/04-templates/CategoryTemplate/CategoryTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-category-template-category-template-tsx" */),
  "component---src-components-04-templates-startseite-dynamisch-startseite-dynamisch-tsx": () => import("./../../../src/components/04-templates/StartseiteDynamisch/StartseiteDynamisch.tsx" /* webpackChunkName: "component---src-components-04-templates-startseite-dynamisch-startseite-dynamisch-tsx" */),
  "component---src-components-04-templates-sub-category-template-sub-category-template-tsx": () => import("./../../../src/components/04-templates/SubCategoryTemplate/SubCategoryTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-sub-category-template-sub-category-template-tsx" */),
  "component---src-components-05-pages-404-tsx": () => import("./../../../src/components/05-pages/404.tsx" /* webpackChunkName: "component---src-components-05-pages-404-tsx" */),
  "component---src-components-05-pages-autorinnen-tsx": () => import("./../../../src/components/05-pages/autorinnen.tsx" /* webpackChunkName: "component---src-components-05-pages-autorinnen-tsx" */),
  "component---src-components-05-pages-datenschutz-tsx": () => import("./../../../src/components/05-pages/datenschutz.tsx" /* webpackChunkName: "component---src-components-05-pages-datenschutz-tsx" */),
  "component---src-components-05-pages-impressum-tsx": () => import("./../../../src/components/05-pages/impressum.tsx" /* webpackChunkName: "component---src-components-05-pages-impressum-tsx" */),
  "component---src-components-05-pages-ueber-uns-tsx": () => import("./../../../src/components/05-pages/ueber_uns.tsx" /* webpackChunkName: "component---src-components-05-pages-ueber-uns-tsx" */)
}

