interface CategoryPartial {
  slug?: string;
}

export const pathForCategory = ({ slug }: CategoryPartial): string => {
  if (!slug) {
    throw Error(`No category slug. Very weird.`);
  }

  return `/kategorie/${slug}`;
};
