/*
 * Lab components are not included in the theme. this fixes the error
 *
 * https://github.com/mui-org/material-ui/issues/19655#issuecomment-864445820
 */
import type {} from "@mui/lab/themeAugmentation";
import {
  Breakpoint,
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
  Theme,
} from "@mui/material/styles";

export const mobileMenuBreakpoint: Breakpoint = "md";

// This could be an alternative to the enum:
// type ColorMode = "contrast" | "regular";

export const primaryMainColor = "#bfffba";

export enum ColorMode {
  Contrast = "Contrast",
  Regular = "Regular",
}

const colorModes: Record<ColorMode, PaletteOptions> = {
  [ColorMode.Regular]: {
    primary: {
      main: primaryMainColor,
    },
    secondary: {
      main: "#7aa6c6",
      light: "#abd7f9",
      contrastText: "#fff",
    },
  },
  [ColorMode.Contrast]: {
    primary: {
      main: "#fff",

      contrastText: "#000",
    },
    secondary: {
      main: "#000",
      contrastText: "#fff",
    },
  },
};

export const createThemeWithColorMode = (colorMode: ColorMode): Theme => {
  const palette = colorModes[colorMode];
  const isRegular = colorMode === ColorMode.Regular;

  const theme = createTheme({
    palette,
    transitions: {},
    typography: {
      allVariants: {},
      fontFamily: "Montserrat Alternates, sans-serif",
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 900,

      h1: {
        fontFamily: "Montserrat Alternates",
        fontWeight: "700",
        fontSize: "41px",
        marginBottom: "15px",
        fontStyle: "italic",
      },
      h2: {
        fontFamily: "Montserrat Alternates",
        fontWeight: "700",
        fontSize: "34px",
        marginTop: "20px",
        fontStyle: "italic",
      },
      h3: {
        fontFamily: "Montserrat Alternates",
        fontWeight: "700",
        fontSize: "28px",
        fontStyle: "italic",
        marginTop: "15px",
      },
      h4: {},
      h5: {
        fontFamily: "Lato",
        fontWeight: 900,
        fontSize: "41px",
      },
      h6: {
        fontFamily: "Lato",
        fontWeight: 900,
        fontSize: "17px",
      },

      body1: {
        fontFamily: "Lato",
        lineHeight: "1.8",
      },
      body2: {},
      caption: {},
      button: {
        fontFamily: "Lato",
        fontWeight: 900,
        fontSize: "17px",
      },
      subtitle1: {
        fontFamily: "Lato",
        fontWeight: 100,
        fontSize: "18px",
      },
      subtitle2: {
        fontFamily: "Lato",
        fontWeight: 100,
        fontSize: "12px",
      },
    },
    spacing: (factor: number) => `${1 * factor}rem`,
  });

  const themeWithComponents = (themeWithoutComponents: Theme): Theme => ({
    ...themeWithoutComponents,
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {},
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        styleOverrides: {
          root: {
            color: theme.palette.common.black,
            textDecorationThickness: "3px",
            textDecorationColor: isRegular
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            "&:hover": {
              textDecorationColor: isRegular
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            },
          },
        },
      },

      MuiToolbar: {
        defaultProps: {},
        styleOverrides: {
          root: {
            width: "100%",
            minHeight: "56px",
            height: "56px",
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
            [theme.breakpoints.up("sm")]: {
              minHeight: "64px",
              height: "64px",
            },
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {},
        styleOverrides: {
          root: {
            marginRight: "0 !important",
          },
        },
      },

      MuiButton: {
        defaultProps: {
          // Button Doku (Varinate etc.)
        },
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  const themeWithResponsiveFontSizes = responsiveFontSizes(
    themeWithComponents(theme),
    {
      factor: 3,
      breakpoints: ["xs", "sm", "md", "lg", "xl"],
    }
  );

  return themeWithResponsiveFontSizes;
};
