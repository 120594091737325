import {
  Box,
  Container,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { useColorMode } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { LogoWhiteAtom } from "../../01-atoms/LogoWhiteAtom/LogoWhiteAtom";
import { useFooterStyles } from "./FooterStyles";

const Footer = (): JSX.Element => {
  const { isContrastMode } = useColorMode();
  const { classes } = useFooterStyles({ isContrastMode });

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Grid
          container={true}
          // spacing={5}
          display="flex"
          justifyContent="space-around"
        >
          <Grid display="flex" alignItems="center" item={true} xs={12} sm={4}>
            <Box mt={4} mb={4}>
              <MuiLink component={GatsbyLink} to={`/`}>
                <LogoWhiteAtom />
              </MuiLink>

              <Typography
                className={classes.customFontColor}
                textAlign="start"
                variant="body1"
              >
                Ein Projekt des Jugendpresse Deutschland e.V.
              </Typography>

              <Typography
                className={classes.customFontColor}
                textAlign="start"
                variant="body1"
              >
                Mauerstr. 83-84, 10117 Berlin
              </Typography>

              <MuiLink underline="always" href={`mailto:kontakt@politikjam.de`}>
                <Typography
                  className={classes.customFontColor}
                  textAlign="start"
                  variant="body1"
                >
                  kontakt@politikjam.de
                </Typography>
              </MuiLink>

              <MuiLink underline="always" href={`tel:+4930394052500`}>
                <Typography
                  className={classes.customFontColor}
                  textAlign="start"
                  variant="body1"
                >
                  +49 30 3940 525 00
                </Typography>
              </MuiLink>

              <Box mt={2}>
                <MuiLink
                  underline="always"
                  component={GatsbyLink}
                  to={`/datenschutz`}
                  className={classes.customFontColor}
                >
                  Datenschutz
                </MuiLink>

                <br />

                <MuiLink
                  underline="always"
                  component={GatsbyLink}
                  to={`/impressum`}
                  className={classes.customFontColor}
                >
                  Impressum
                </MuiLink>
              </Box>
            </Box>
          </Grid>

          <Grid
            className={classes.gridItemPartnerlogos}
            item={true}
            xs={12}
            sm={8}
            md={8}
          >
            <Box
              className={classes.partnerLogoBox}
              mt={4}
              mb={4}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <MuiLink
                target="_blank"
                rel="noreferrer"
                href={`https://www.bmfsfj.de/`}
              >
                <img
                  src="/partnerlogos/bmfsfj_gefoerdert.jpg"
                  className={classes.bmfsfjImage}
                />
              </MuiLink>

              <MuiLink
                target="_blank"
                rel="noreferrer"
                href={`https://jugendstrategie.de/`}
              >
                <img
                  src="/partnerlogos/jugendstrategie.png"
                  className={classes.jugendstrategieImage}
                />
              </MuiLink>

              <MuiLink
                target="_blank"
                rel="noreferrer"
                href={`https://jugendpresse.de/`}
              >
                <img
                  src="/partnerlogos/jugendpresse.png"
                  className={classes.jungendpresseImage}
                />
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
