import { Box, IconButton, Link as MuiLink } from "@mui/material";
import { useBooleanState } from "@nvon/react-toolbox";
import { Link } from "gatsby";
import { Burgermenu } from "../../../__generated__/svgComponents/Burgermenu";
import { DrawerMobileMenu } from "../DrawerMobileMenu/DrawerMobileMenu";
import { MobileMenuProps } from "./MobileMenuInterfaces";
import { useMobileMenuStyles } from "./MobileMenuStyles";

const MobileMenu = (props: MobileMenuProps): JSX.Element => {
  const { classes, cx } = useMobileMenuStyles();

  const {
    state: isMobileMenuOpen,
    on: showMobileMenu,
    off: hideMobileMenu,
  } = useBooleanState(false);

  return (
    <Box className={cx(classes.wrapper, props.className)} style={props.style}>
      <IconButton
        disableFocusRipple={true}
        disableTouchRipple={true}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={showMobileMenu}
      >
        {/* <MenuIcon /> */}

        <Burgermenu />
      </IconButton>

      <DrawerMobileMenu isOpen={isMobileMenuOpen} onClose={hideMobileMenu}>
        <Box className={classes.linkListWrapper}>
          {props.menuItems.map((menuItem) => (
            <MuiLink
              component={Link}
              key={menuItem.href}
              to={menuItem.href}
              onClick={hideMobileMenu}
            >
              {menuItem.label}
            </MuiLink>
          ))}
        </Box>
      </DrawerMobileMenu>
    </Box>
  );
};

export default MobileMenu;
