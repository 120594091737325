import { Breakpoint } from "@mui/material";
import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";

const mobileMenuBreakpoint: Breakpoint = "lg";

interface HeaderNavigationStyleProps {
  isMounted: boolean;
}

export const useHeaderNavigationStyles =
  makeStyles<HeaderNavigationStyleProps>()((theme, { isMounted }) => ({
    wrapper: {},
    menuClass: {
      display: "none",
      "&.isActive": {
        display: "block",
      },
    },
    pointer: {
      cursor: "pointer",
    },
    toolbar: {
      "& > *": {
        transition: "opacity 0.2s ease-in-out",
        opacity: isMounted ? important(1) : undefined,
      },
    },
    position: {
      marginLeft: "20px",
      "@media (max-width: 1200px)": {
        marginRight: "20px",
      },
    },
    logoLink: {
      display: "flex",
      "& ": {
        height: "400px",
      },
    },
    logoWrapper: {
      textOverflow: "clip",
      // wieso funktioniert das nicht?
      transform: "translateY(4px)",
    },
    mobileMenu: {
      display: "none",
      [theme.breakpoints.down(mobileMenuBreakpoint)]: {
        display: "block",
      },
    },
    desktopMenu: {
      display: "none !important",
      [theme.breakpoints.up(mobileMenuBreakpoint)]: {
        display: "flex !important",
      },
    },
    logoSize: {
      marginRight: "70px",

      width: "140px",
      "@media (max-width: 876px)": {
        maxWidth: "140px",
        marginRight: "0px",
      },
    },
  }));
