import { makeStyles } from "tss-react/mui";

export const useFooterStyles = makeStyles<{ isContrastMode: boolean }>()(
  (theme, { isContrastMode }) => ({
    wrapper: {
      backgroundColor: theme.palette.secondary.main,
      "& .MuiLink-underlineAlways": {
        textDecorationColor: isContrastMode
          ? theme.palette.common.white
          : undefined,
      },
    },
    customFontColor: {
      color: "#FFFFFF",
    },
    jungendpresseImage: {
      margin: theme.spacing(2),
      width: "100px",
      marginRight: "50px",
      "@media (max-width: 1100px)": {
        paddingBottom: "10px",
        marginRight: theme.spacing(2),
      },
    },
    jugendstrategieImage: {
      margin: theme.spacing(2),
      width: "160px",

      "@media (max-width: 1100px)": {
        marginTop: "-10px",
      },
    },
    bmfsfjImage: {
      // Wieso geht das nicht?
      // marginTop: "100px !important",
      margin: theme.spacing(2),
      width: "200px",
    },
    partnerLogoBox: {
      backgroundColor: "#FFFFFF",
      flexDirection: "row",
      "@media (max-width: 1100px)": {
        flexDirection: "column",
      },

      // marginBlock: theme.spacing(4),
    },
    gridItemPartnerlogos: {
      display: "flex",
      justifyContent: "end",

      "@media (max-width: 1100px)": {
        justifyContent: "space-around",
      },
      "@media (max-width: 890px)": {
        justifyContent: "end",
      },
      "@media (max-width: 600px)": {
        justifyContent: "center",
        marginTop: theme.spacing(-3.5),
      },
    },
  })
);
