import { makeStyles } from "tss-react/mui";

export const useMobileMenuStyles = makeStyles()((theme) => ({
  wrapper: {},
  linkListWrapper: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
